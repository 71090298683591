<template>
	<div>
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="60vh" class="">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Nombre</b-th>
						<b-th>Estado</b-th>
						<b-th>Creado por</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.id">
						<b-td>{{ row.name }}</b-td>
						<b-td>
							<b-badge :variant="row.status_id == 1 ? 'success' : 'danger'">{{ row.status }}</b-badge>
						</b-td>
						<b-td>
							{{ row.created_by.user }}
							<br />
							<small>{{ row.created_by.date | myGlobalDayShort }}</small>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center" style="gap: 0.5rem">
								<b-button size="sm" variant="flat-warning" @click="edit(row)">
									<feather-icon icon="EditIcon" />
								</b-button>
								<b-button size="sm" variant="flat-danger" @click="remove(row)">
									<feather-icon icon="TrashIcon" />
								</b-button>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="4">
							<p class="mb-0">No hay registros por mostrar</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<b-modal centered :title=" creationModal && creationModal.id ? 'Editar suplemento' : 'Registrar nuevo suplemento'" v-model="creationModal.active" @hidden="handleHidden">
			<b-overlay :show="creationModal.loading">
				<b-form-group label="Nombre">
					<b-form-input v-model="creationModal.name" />
				</b-form-group>
				<b-form-group label="Estado" v-if="creationModal.editing">
					<b-form-select
						v-model="creationModal.status"
						:options="[
							{ value: 1, text: 'Activo' },
							{ value: 2, text: 'Inactivo' },
						]"
					/>
				</b-form-group>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="info" @click="edit()" v-if="creationModal.editing" :disabled="!!!creationModal.name"
					>Editar</b-button
				>
				<b-button variant="success" @click="create" v-else :disabled="!!!creationModal.name">Guardar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import preparacionService from "@/services/preparacion/settings.service";
// @/services/preparacion/settings.service.js

export default {
	props: {
		action: String,
	},
	data: () => ({
		isLoading: false,
		rows: [],
		creationModal: {
			active: false,
			id: null,
			name: "",
			status: null,
			loading: false,
			editing: false,
		},
	}),
	methods: {
		handleHidden() {
			this.creationModal = { active: false, id: null, name: "", status: null, loading: false, editing: false };
			this.getRows();
			this.$emit("actionDone");
		},
		async getRows() {
			this.isLoading = true;
			const { data } = await preparacionService.getVitamins({ status: null });
			this.rows = data;
			this.isLoading = false;
		},
		async create() {
			this.creationModal.loading = true;
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se registrara el suplemento ${this.creationModal.name}`,
			});
			if (!isConfirmed) {
				this.handleHidden();
				return;
			}
			const { message, status } = await preparacionService.createVitamin({ name: this.creationModal.name });

			// console.log('status', status);

			if (status) {
				this.handleHidden();

				this.showToast("success", "top-right", "Suplementos", "SuccessIcon", message);
				return;
			}
			this.creationModal.loading = false;
			this.getRows();
			console.log('reload');
		},
		async edit(row) {
			if (row) {
				this.creationModal.id = row.id;
				this.creationModal.name = row.name;
				this.creationModal.status = row.status_id;
				this.creationModal.active = true;
				this.creationModal.editing = true;
				return;
			}
			this.creationModal.loading = true;
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se actualizara el suplemento ${this.creationModal.name}`,
			});
			if (!isConfirmed) {
				this.handleHidden();
				return;
			}
			const { message, status } = await preparacionService.editVitamin({
				id: this.creationModal.id,
				name: this.creationModal.name,
				status: this.creationModal.status,
			});
			if (status) {
				this.creationModal.loading = false;
				this.handleHidden();
				this.getRows();
				return;
			}
			this.creationModal.loading = false;
		},
		async remove(row) {
			this.isLoading = true;
			const { isConfirmed } = await this.showConfirmSwal({ text: `Se eliminara el suplemento ${row.name}` });
			if (!isConfirmed) {
				this.isLoading = false;
				return;
			}
			await preparacionService.deleteVitamin({ id: row.id });

			this.isLoading = false;
			this.getRows();
		},
	},
	created() {
		this.getRows();
	},
	watch: {
		action(val) {
			if (val == "create-vitamin") {
				this.creationModal.active = !this.creationModal.active;
			}
		},
	},
};
</script>
